import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Icon, IconActionArrowBack, IconActionArrowForward, SegmentedControl, SegmentedControlItem, Size, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { FigmaEmbed, ComponentLinks } from '../common';
import { colorBrand } from '@hse-design/tokens';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "segmentedcontrol"
    }}>{`SegmentedControl`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { HseSegmentedControl } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=3736%3A0'} storybook={'/?path=/story/inputs-segmentedcontrol--playground'} vueStorybook={'/?path=/story/inputs-segmentedcontrol--playground'} name={'SegmentedControl'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`SegmentedControl — это компонент управления, состоящий из нескольких сегментов. Используется для выбора одного варианта из нескольких.`}</p>
    <p>{`Все сегменты должны иметь одинаковую ширину. Сегменты могут содержать произвольный контент. Если используется иконка внутри сегмента, желательно, поддерживать этот прием и в других сегментах.`}</p>
    <p>{`У компонента 3 размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Размер задаётся пропом `}<inlineCode parentName="p">{`size`}</inlineCode>{`. По умолчанию используется `}<inlineCode parentName="p">{`medium`}</inlineCode>{`.`}</p>
    <p>{`Компонентом можно управлять с помощью клавиатуры. Стрелками вправо и влево можно сфокусироваться на сегменте, с помощью пробела выбрать сегмент. Для управления с клавиатуры нужно сфокусироваться на компоненте`}</p>
    <p>{`Выбранным сегментом нужно управлять извне: вы можете обрабатывать `}<inlineCode parentName="p">{`onChange`}</inlineCode>{` и передавать значение выбранного сегмента (`}<inlineCode parentName="p">{`value`}</inlineCode>{`) в проп `}<inlineCode parentName="p">{`value`}</inlineCode>{` компонента.`}</p>
    <Playground __position={1} __code={'() => {\n  const [value, setValue] = useState(\'1\')\n  return (\n    <SegmentedControl\n      value={value}\n      onChange={setValue}\n      items={[\n        { label: \'Сегмент\', value: \'1\' },\n        { label: \'Сегмент\', value: \'2\' },\n        { label: \'Сегмент\', value: \'3\' },\n        { label: \'Сегмент\', value: \'4\' },\n        { label: \'Сегмент\', value: \'5\' },\n      ]}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Icon,
      IconActionArrowBack,
      IconActionArrowForward,
      SegmentedControl,
      SegmentedControlItem,
      Size,
      Space,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      colorBrand,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('1');
        return <SegmentedControl value={value} onChange={setValue} items={[{
          label: 'Сегмент',
          value: '1'
        }, {
          label: 'Сегмент',
          value: '2'
        }, {
          label: 'Сегмент',
          value: '3'
        }, {
          label: 'Сегмент',
          value: '4'
        }, {
          label: 'Сегмент',
          value: '5'
        }]} mdxType="SegmentedControl" />;
      }}
    </Playground>
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node={'?node-id=10013:33180'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node={'?node-id=10013:33235'} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "размер"
    }}>{`Размер`}</h3>
    <p>{`У компонента 3 размера: `}<inlineCode parentName="p">{`small`}</inlineCode>{`, `}<inlineCode parentName="p">{`medium`}</inlineCode>{`, `}<inlineCode parentName="p">{`large`}</inlineCode>{`. Размер задаётся пропом `}<inlineCode parentName="p">{`size`}</inlineCode>{`. По умолчанию используется `}<inlineCode parentName="p">{`medium`}</inlineCode></p>
    <Playground __position={4} __code={'() => {\n  const [value, setValue] = useState(\'1\')\n  const props = {\n    value,\n    onChange: setValue,\n    items: [\n      { label: \'Сегмент\', value: \'1\' },\n      { label: \'Сегмент\', value: \'2\' },\n      { label: \'Сегмент\', value: \'3\' },\n      { label: \'Сегмент\', value: \'4\' },\n      { label: \'Сегмент\', value: \'5\' },\n    ],\n  }\n  return (\n    <>\n      <SegmentedControl size={SegmentedControl.Size.small} {...props} />\n      <Space size={Size.small_2x} vertical />\n      <SegmentedControl {...props} />\n      <Space size={Size.small_2x} vertical />\n      <SegmentedControl size={SegmentedControl.Size.large} {...props} />\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Icon,
      IconActionArrowBack,
      IconActionArrowForward,
      SegmentedControl,
      SegmentedControlItem,
      Size,
      Space,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      colorBrand,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('1');
        const props = {
          value,
          onChange: setValue,
          items: [{
            label: 'Сегмент',
            value: '1'
          }, {
            label: 'Сегмент',
            value: '2'
          }, {
            label: 'Сегмент',
            value: '3'
          }, {
            label: 'Сегмент',
            value: '4'
          }, {
            label: 'Сегмент',
            value: '5'
          }]
        };
        return <>
        <SegmentedControl size={SegmentedControl.Size.small} {...props} mdxType="SegmentedControl" />
        <Space size={Size.small_2x} vertical mdxType="Space" />
        <SegmentedControl {...props} mdxType="SegmentedControl" />
        <Space size={Size.small_2x} vertical mdxType="Space" />
        <SegmentedControl size={SegmentedControl.Size.large} {...props} mdxType="SegmentedControl" />
      </>;
      }}
    </Playground>
    <h2 {...{
      "id": "состояния-disabled-и-inactive"
    }}>{`Состояния disabled и inactive`}</h2>
    <p>{`Состояния `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` и `}<inlineCode parentName="p">{`inactive`}</inlineCode>{` используются для запрета выбора варианта. Состояния имеют одинаковое поведение и внешний вид.`}</p>
    <Playground __position={5} __code={'() => {\n  const [value, setValue] = useState(\'1\')\n  return (\n    <SegmentedControl\n      size={SegmentedControl.Size.medium}\n      value={value}\n      onChange={setValue}\n      items={[\n        { label: \'Сегмент\', value: \'1\' },\n        { label: \'Сегмент\', value: \'2\' },\n        { label: \'Сегмент\', value: \'3\', inactive: true },\n        { label: \'Сегмент\', value: \'4\', disabled: true },\n        { label: \'Сегмент\', value: \'5\' },\n      ]}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Icon,
      IconActionArrowBack,
      IconActionArrowForward,
      SegmentedControl,
      SegmentedControlItem,
      Size,
      Space,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      colorBrand,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('1');
        return <SegmentedControl size={SegmentedControl.Size.medium} value={value} onChange={setValue} items={[{
          label: 'Сегмент',
          value: '1'
        }, {
          label: 'Сегмент',
          value: '2'
        }, {
          label: 'Сегмент',
          value: '3',
          inactive: true
        }, {
          label: 'Сегмент',
          value: '4',
          disabled: true
        }, {
          label: 'Сегмент',
          value: '5'
        }]} mdxType="SegmentedControl" />;
      }}
    </Playground>
    <h2 {...{
      "id": "произвольный-контент-сегментов"
    }}>{`Произвольный контент сегментов`}</h2>
    <p>{`Сегменту передавать произвольный контент в свойство `}<inlineCode parentName="p">{`label`}</inlineCode>{`: например, иконку с текстом`}</p>
    <Playground __position={6} __code={'() => {\n  const [value, setValue] = useState(\'1\')\n  const CustomSegment = (icon = IconActionArrowBack, text = \'Назад\') => (\n    <div\n      style={{\n        display: \'flex\',\n        flexFlow: \'row nowrap\',\n        alignItems: \'center\',\n      }}\n    >\n      <Icon style={{ color: colorBrand, marginRight: \'4px\' }} icon={icon} />{\' \'}\n      {text}\n    </div>\n  )\n  return (\n    <SegmentedControl\n      size={SegmentedControl.Size.medium}\n      value={value}\n      onChange={setValue}\n      items={[\n        {\n          label: CustomSegment(),\n          value: \'1\',\n        },\n        { label: \'Сегмент\', value: \'2\' },\n        { label: \'Сегмент\', value: \'3\', inactive: true },\n        { label: \'Сегмент\', value: \'4\', disabled: true },\n        {\n          label: CustomSegment(IconActionArrowForward, \'Вперед\'),\n          value: \'5\',\n        },\n      ]}\n    />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Icon,
      IconActionArrowBack,
      IconActionArrowForward,
      SegmentedControl,
      SegmentedControlItem,
      Size,
      Space,
      Playground,
      Props,
      FigmaEmbed,
      ComponentLinks,
      colorBrand,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [value, setValue] = useState('1');

        const CustomSegment = (icon = IconActionArrowBack, text = 'Назад') => <div style={{
          display: 'flex',
          flexFlow: 'row nowrap',
          alignItems: 'center'
        }}>
        <Icon style={{
            color: colorBrand,
            marginRight: '4px'
          }} icon={icon} mdxType="Icon" />{' '}
        {text}
      </div>;

        return <SegmentedControl size={SegmentedControl.Size.medium} value={value} onChange={setValue} items={[{
          label: CustomSegment(),
          value: '1'
        }, {
          label: 'Сегмент',
          value: '2'
        }, {
          label: 'Сегмент',
          value: '3',
          inactive: true
        }, {
          label: 'Сегмент',
          value: '4',
          disabled: true
        }, {
          label: CustomSegment(IconActionArrowForward, 'Вперед'),
          value: '5'
        }]} mdxType="SegmentedControl" />;
      }}
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={SegmentedControl} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "пропы-segmentedcontrolitem"
    }}>{`Пропы SegmentedControlItem`}</h2>
    <Props of={SegmentedControlItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      